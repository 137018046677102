import React from 'react';
import './header.scss';

const Header = ({ youtube, linkedIn, twitter }) => {
  return (
    <div className="header" id="header">
      <div className="header__header-content">
        <div className="header__header-content__right">
          <a
            className={'header__header-content__right__logo'}
            href={youtube == '' ? '#' : youtube}
            target={youtube == '' ? '' : '_blank'}
            rel={youtube == '' ? '' : 'noopener noreferrer'}
          >
            <img src={'/img/youtube-blue.svg'} alt={'youtube'} />
          </a>
          <a
            className={'header__header-content__right__logo'}
            href={twitter == '' ? '#' : twitter}
            target={twitter == '' ? '' : '_blank'}
            rel={twitter == '' ? '' : 'noopener noreferrer'}
          >
            <img src={'/img/twitter-blue.svg'} alt={'twitter'} />
          </a>
          <a
            className={'header__header-content__right__logo'}
            href={linkedIn == '' ? '#' : linkedIn}
            target={linkedIn == '' ? '' : '_blank'}
            rel={linkedIn == '' ? '' : 'noopener noreferrer'}
          >
            <img src={'/img/linkedIn-blue.svg'} alt={'linkedIn'} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Header;
