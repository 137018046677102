import React from 'react';

import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import Header from '../components/header/header';

const Head = () => {
  const data = useStaticQuery(GET_HEADER);

  return (
    <Header
      twitter={data.markdownRemark.frontmatter.twitter}
      youtube={data.markdownRemark.frontmatter.youtube}
      linkedIn={data.markdownRemark.frontmatter.linkedIn}
    />
  );
};
const GET_HEADER = graphql`
  query getHeaderQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "header" } }) {
      frontmatter {
        twitter
        linkedIn
        youtube
      }
    }
  }
`;
export default Head;
